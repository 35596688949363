import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from '../layout/layout.jsx'
import Helmet from 'react-helmet'
import posed from 'react-pose'
import { easeFunction } from '../helper/variables.jsx'
import ScrollManager from '../helper/scrollManager.jsx'
import Parser from 'html-react-parser'
import Circle from '../components/graphic/circle'
import Sparks from '../components/graphic/sparks'

import './thank-you.css'
import { graphql } from 'gatsby'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class ApplicationThankYou extends Component {
  state = {
    mainNode: undefined,
    scrollValue: 0,
    momentumScrollValue: 0,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
  }

  componentDidMount() {
ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    this.props.backgroundWhite()

    this.setState({
      mainNode: this.mainNode.current,
    })

    this.props.backgroundWhite()
    this.props.noHomepageLoading()
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  render() {
    const page = this.props.data.contentfulLetsTalkThankYou

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{page.metaData.metaTitle}</title>
          <meta name="description" content={page.metaData.description} />
          <meta name="keywords" content={page.metaData.keywords} />
          <meta property="og:title" content={page.metaData.metaTitle} />
          <meta property="og:description" content={page.metaData.description} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              page.metaData.ogImage
                ? `https:${page.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta
            property="og:url"
            content="https://www.baunfire.com/application-thank-you"
          />
          <link
            rel="canonical"
            href="https://www.baunfire.com/application-thank-you"
          />
        </Helmet>
        <PageTransition>
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <div className="thank-you-circle">
              <Circle />
            </div>
            <div className="content-container page-thank-you">
              <div className="talk-spark">
                <Sparks type="1" defaultGray />
              </div>
              <h1 className="type-h1 headline top-of-page">{page.headline}</h1>
              <div className="subcopy">
                {Parser(page.subCopy.childContentfulRichText.html)}
              </div>
            </div>
          </ScrollManager>
        </PageTransition>
      </Layout>
    )
  }
}

export const query = graphql`
  query($page: String = "Thank you") {
    contentfulLetsTalkThankYou(title: { eq: $page }) {
      metaData {
        title
        metaTitle
        description
        keywords
      }
      headline
      subCopy {
        childContentfulRichText {
          html
        }
      }
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedLetsTalk = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationThankYou)

export default ConnectedLetsTalk
